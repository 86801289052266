import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {ProduktDTO} from '../../openapi/fakturierung-openapi';
import {nachgeladenesProdukt, produktEntitiesMock} from '../data/produkt-entities.mock';
import {HttpErrorResponse} from '@angular/common/http';


@Injectable()
export class MockProduktService {

  getProdukt(inhaberId: string, produktId: string): Observable<ProduktDTO> {

    let produkt: ProduktDTO | undefined;
    if (produktId === '999') {
      produkt = nachgeladenesProdukt;
    } else {
      produkt = produktEntitiesMock.entities[produktId];
    }

    if (produkt) {
      return of(produkt);
    } else {
      // INFO: Simuliere einen 404-Fehler
      const errorResponse = new HttpErrorResponse({
        error: 'Produkt nicht gefunden',
        status: 404,
        statusText: 'Not Found',
        url: `/api/v1/inhaber/${inhaberId}/produkte/${produktId}`
      });
      return throwError(() => errorResponse);
    }
  }

}

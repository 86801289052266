import {EntityState} from '@ngrx/entity';
import {BerechnungsartDTOBerechnungsartEnum, ProduktDTO} from '../../openapi/fakturierung-openapi';
import {einheitenKilogrammMock, einheitenStueckMock, einheitenTonnenMock} from './einheiten.mock';


type ProduktDTOEntityState = EntityState<ProduktDTO> & { ids: string[] };


export const produktEntitiesMock: ProduktDTOEntityState = {
  entities: {
    '1': {
      id: '1',
      inhaberId: '2',
      produktbezeichnung: 'Das ist ein Produkt A, das sehr gut ist.',
      produktnummer: 1001,
      produktbeschreibung: 'Dies ist Produkt A',
      berechnungsart: {id: 'NETTO', berechnungsart: BerechnungsartDTOBerechnungsartEnum.Netto},
      ustProzentsatz: {prozentsatz: 0.19, id: '0.19'},
      betragssumme: 500,
      einheit: einheitenKilogrammMock,
    },
    '2': {
      id: '2',
      inhaberId: '2',
      produktbezeichnung: 'Das Produkt B ist auch nicht schlecht.',
      produktnummer: 1002,
      produktbeschreibung: 'Dies ist Produkt B',
      berechnungsart: {id: 'NETTO', berechnungsart: BerechnungsartDTOBerechnungsartEnum.Netto},
      ustProzentsatz: {prozentsatz: 0.19, id: '0.19'},
      betragssumme: 1000,
      einheit: einheitenTonnenMock,
    },
    '3': {
      id: '3',
      inhaberId: '2',
      produktbezeichnung: 'Von diesem Produkt C werden Sie begeistert sein.',
      produktnummer: 1003,
      produktbeschreibung: 'Dies ist Produkt C. Die Beschreibung ist zwar etwas länger, damit sie über mehrere Zeilen geht, aber die Qualität des Produktes ist dennoch ausgezeichnet.',
      berechnungsart: {id: 'NETTO', berechnungsart: BerechnungsartDTOBerechnungsartEnum.Netto},
      ustProzentsatz: {prozentsatz: 0.07, id: '0.07'},
      betragssumme: 1500,
      einheit: einheitenKilogrammMock,
    },
    '4': {
      id: '4',
      inhaberId: '2',
      produktbezeichnung: 'Produkt D ist aber auch nicht zu verachten.',
      produktnummer: 1004,
      produktbeschreibung: 'Dies ist Produkt D',
      berechnungsart: {id: 'NETTO', berechnungsart: BerechnungsartDTOBerechnungsartEnum.Brutto},
      ustProzentsatz: {prozentsatz: 0.19, id: '0.19'},
      betragssumme: 234234,
      einheit: einheitenTonnenMock,
    },
    '5': {
      id: '5',
      inhaberId: '2',
      produktbezeichnung: 'Von Produkt E sollten Sie allerdings die Finger lassen. Von Produkt E sollten Sie allerdings die Finger lassen.',
      produktnummer: 1005,
      produktbeschreibung: 'Dies ist Produkt E',
      berechnungsart: {id: 'NETTO', berechnungsart: BerechnungsartDTOBerechnungsartEnum.Netto},
      ustProzentsatz: {prozentsatz: 0.19, id: '0.19'},
      betragssumme: 892,
      einheit: einheitenKilogrammMock,
    },
    '6': {
      id: '6',
      inhaberId: '2',
      produktbezeichnung: 'Produkt F ist auch nicht schlecht.',
      produktnummer: 1006,
      produktbeschreibung: 'Dies ist Produkt F',
      berechnungsart: {id: 'NETTO', berechnungsart: BerechnungsartDTOBerechnungsartEnum.Netto},
      ustProzentsatz: {prozentsatz: 0.19, id: '0.19'},
      betragssumme: 234,
      einheit: einheitenStueckMock,
    },
    '7': {
      id: '7',
      inhaberId: '2',
      produktbezeichnung: 'Produkt G',
      produktnummer: 1007,
      produktbeschreibung: 'Dies ist Produkt G',
      berechnungsart: {id: 'NETTO', berechnungsart: BerechnungsartDTOBerechnungsartEnum.Netto},
      ustProzentsatz: {prozentsatz: 0.19, id: '0.19'},
      betragssumme: 89893,
      einheit: einheitenTonnenMock,
    },
    '8': {
      id: '8',
      inhaberId: '2',
      produktbezeichnung: 'Produkt H',
      produktnummer: 1008,
      produktbeschreibung: 'Dies ist Produkt H',
      berechnungsart: {id: 'NETTO', berechnungsart: BerechnungsartDTOBerechnungsartEnum.Netto},
      ustProzentsatz: {prozentsatz: 0.19, id: '0.19'},
      betragssumme: 344,
      einheit: einheitenKilogrammMock,
    },
    '9': {
      id: '9',
      inhaberId: '2',
      produktbezeichnung: 'Produkt I',
      produktnummer: 1009,
      produktbeschreibung: 'Dies ist Produkt I',
      berechnungsart: {id: 'NETTO', berechnungsart: BerechnungsartDTOBerechnungsartEnum.Netto},
      ustProzentsatz: {prozentsatz: 0.19, id: '0.19'},
      betragssumme: 8792,
      einheit: einheitenStueckMock,
    },
    '10': {
      id: '10',
      inhaberId: '2',
      produktbezeichnung: 'Wie bitte? Nein ich bin garkein Produkt.',
      produktnummer: 1,
      produktbeschreibung: 'Bitte lassen sie mich raus, ich bin in diese Tabelle gefangen!',
      berechnungsart: {id: 'NETTO', berechnungsart: BerechnungsartDTOBerechnungsartEnum.Netto},
      betragssumme: 500,
      ustProzentsatz: {prozentsatz: 0.19, id: '0.19'},
      einheit: einheitenKilogrammMock,
    },
  },
  ids: [
    '1', '2', '3', '4', '5', '6', '7', '8', '9', '10',
  ]
};

export const nachgeladenesProdukt: ProduktDTO = {
  id: '999',
  inhaberId: '2',
  produktbezeichnung: 'Osterei',
  produktnummer: 1,
  produktbeschreibung: 'Bunte Ostereier in Gang 4',
  berechnungsart: {id: 'NETTO', berechnungsart: BerechnungsartDTOBerechnungsartEnum.Netto},
  betragssumme: 500,
  ustProzentsatz: {prozentsatz: 0.19, id: '0.19'},
  einheit: einheitenStueckMock,
};

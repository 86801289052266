import {createAction, props} from '@ngrx/store';
import {CreateProduktRequestDTO, ProduktDTO} from '../../openapi/fakturierung-openapi';


export class ProduktEntitiesActions {

  public static loadProduktByID = createAction(
    '[Produkt Entities] Load produkt by ID.',
    props<{
      inhaberId: string;
      produktId: string;
    }>(),
  );

  public static loadProduktByIdIfAbsent = createAction(
    '[Produkt Entities] Load produkt by ID if absent.',
    props<{
      inhaberId: string;
      produktId: string;
    }>(),
  );

  public static loadProduktByIdSuccess = createAction(
    '[Produkt Entities] Load produkt successfully.',
    props<{
      produkt: ProduktDTO;
    }>(),
  );

  public static loadProduktByIdFailure = createAction(
    '[Produkt Entities] Load produkt failed.',
    props<{
      error: any;
    }>(),
  );

  public static createProdukt = createAction(
    '[Create Produkt Dialog] Create produkt.',
    props<{
      inhaberId: string,
      produktDto: CreateProduktRequestDTO
    }>(),
  );

  public static createProduktSuccess = createAction(
    '[Produkt Entities] Create produkt successfully.',
  );

  public static createProduktFailure = createAction(
    '[Produkt Entities] Create produkt failed.',
    props<{ error: any }>(),
  );

  public static readNextProduktnummer = createAction(
    '[Produkt Entities] Read next Poduktnummer.',
    props<{
      inhaberId: string;
    }>(),
  );

  public static readNextProduktnummerSuccess = createAction(
    '[Produkt Entities] Read next Poduktnummer.',
    props<{
      produktnummer: number;
    }>(),
  );

  public static readNextProduktnummerFailure = createAction(
    '[Produkt Entities] Read next Poduktnummer.',
    props<{
      error: any;
    }>(),
  );

}

import {fakturierungsbelegEntitiesAdapter} from '../adapters/fakturierungsbeleg-entities.adapter';
import {createSelector, MemoizedSelector} from '@ngrx/store';
import {AppState} from '../states/app.state';
import {FakturierungsbelegDTO, ProduktDTO} from '../../openapi/fakturierung-openapi';
import {identity} from 'rxjs';
import {ProduktEntitiesSelectors} from './produkt-entities.selectors';
import {Position} from '../../interfaces/position.interface';


export class FakturierungsbelegEntitiesSelectors {

  private static adapterSelectors = fakturierungsbelegEntitiesAdapter.getSelectors();

  /**
   * Die `select`-Eigenschaft ist ein Objekt, das Funktionen definiert, jede Funktion selektiert eine
   * bestimmte Eigenschaft aus dem State.
   * Das sind grundlegende Selektoren, die im ganzen Projekt als Bausteine für komplexere Selektoren wiederverwendet
   * werden können.
   */
  private static select = {
    belegEntities: (state: AppState) => state.fakturierungsbelegEntities,
    count: (state: AppState) => state.fakturierungsbelegEntities.count,
  };

  public static all = createSelector(
    this.select.belegEntities,
    this.adapterSelectors.selectAll,
  );

  /**
   * Selektor, der einen Fakturierungsbeleg anhand seiner ID zurückgibt.
   * Der Fakturierungsbeleg enthält auch die zugehörigen Positionen und Produkte.
   * Diese werden direkt aus den ProduktEntities abgeleitet.
   *
   * @param fakturierungsbelegId
   */
  public static fakturierungsbelegById = (fakturierungsbelegId: string) => createSelector(
    this.all,
    ProduktEntitiesSelectors.all,
    (belege, produkte) => {
      // INFO: Hier wird der Fakturierungsbeleg anhand seiner ID aus der Liste der Belege gefiltert.
      const beleg = belege.find(beleg => beleg.id === fakturierungsbelegId);

      // INFO: Hier werden die Positionen des Fakturierungsbelegs mit den Produkten verknüpft.
      const positionen: Position[] = beleg?.positionen?.map(position => {

        // INFO: Hier wird das Produkt anhand der ProduktID aus der Liste der Produkte gefiltert.
        const produkt = produkte.find(produkt => produkt.id === position.produktId)
        return {...position, produkt};
      }) || [];
      return {...beleg, positionen};
    },
  );

  /**
   * Selektor, der Fakturierungsbelege anhand einer Liste von IDs zurückgibt und dabei deren Reihenfolge beibehält.
   *
   * @param {string} inhaberId - Die ID des Inhabers der Belege.
   * @param {string[]} fakturierungsbelegIds - Ein Array von BelegIDs.
   * Die Reihenfolge dieses Arrays bestimmt die Reihenfolge der zurückgegebenen Fakturierungsbelege.
   *
   * @returns {MemoizedSelector<AppState, FakturierungsbelegDTO[]>} Ein Selektor, der ein Array von Belegen zurückgibt, die den
   * angegebenen IDs entsprechen, in der in `belegIds` angegebenen Reihenfolge für den ausgewählten Inhaber.
   * Gibt ein leeres Array zurück, wenn keine übereinstimmenden Fakturierungsbelege gefunden wurden.
   */
  public static fakturierungsbelegeByIds = (fakturierungsbelegIds: string[]): MemoizedSelector<AppState, FakturierungsbelegDTO[]> => createSelector(
    this.all,
    belegDtos => {
      const belegMap = new Map(belegDtos.map(belegDto => [belegDto.id, belegDto]));
      return fakturierungsbelegIds
        .map(id => belegMap.get(id))
        .filter((belegDto): belegDto is FakturierungsbelegDTO => !!belegDto);
    }
  );

  public static fakturierungsbelegeByInhaberId = (inhaberId: string) => createSelector(
    this.all,
    belegDtos => belegDtos.filter(belegDto => belegDto && belegDto.inhaberId === inhaberId),
  );

  public static count = createSelector(
    this.select.count,
    identity,
  );

}

import {createReducer, on} from '@ngrx/store';
import {CreateProduktDialogState} from '../states/create-produkt-dialog.state';
import {CreateProduktDialogActions} from '../actions/create-produkt-dialog.actions';
import {ProduktEntitiesActions} from '../actions/produkt-entities.actions';
import {produktBerechnungsartenMock, produktEinheitenMock, produktUstSaetzeMock} from '../../mocks/data/create-produkt-dialog.mock';


export const initialCreateProduktDialogState: CreateProduktDialogState = {
  isOpen: false,
  produkt: undefined,
  availableEinheiten: produktEinheitenMock,
  umsatzsteuersaetze: produktUstSaetzeMock,
  berechnungsarten: produktBerechnungsartenMock,
};

export const createProduktDialogReducer = createReducer(
  initialCreateProduktDialogState,

  on(
    CreateProduktDialogActions.open,
    (state, action) => ({
      ...state,
      isOpen: true,
    })
  ),

  on(
    CreateProduktDialogActions.close,
    () => ({
      ...initialCreateProduktDialogState,
    })
  ),
);

import {InhaberDTO} from '../../openapi/fakturierung-openapi';


export const inhaberMock: InhaberDTO[] = [
  {
    id: `2`,
    nummer: 4,
    bezeichnung: `Betrieb Stettin, Herbert und Partner`,
    geschaeftstaetigkeit: `Landwirtschaft`
  },
  {
    id: '9c963056-0149-4db9-b76e-d76d10f9a63f',
    betriebsnummer: 1337,
    nummer: 1,
    bezeichnung: 'Leetlings-Betrieb'
  },
  {
    id: '458213a6-73c4-419b-9f8b-cec5a2d3c916',
    betriebsnummer: 1312,
    nummer: 2,
    bezeichnung: 'Betrieb Stettin - Zuchtbullen'
  },
  {
    id: 'a2261b35-2ea0-47d7-a668-1812217d3539',
    betriebsnummer: 161,
    nummer: 3,
    bezeichnung: 'Betrieb Stettin - Windpark'
  },
];

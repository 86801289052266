import {createReducer, on} from '@ngrx/store';
import {ProduktColumn, ProduktTableState} from '../states/produkt-table.state';
import {ProduktTableActions} from '../actions/produkt-table.actions';
import {produktEntitiesMock} from '../../mocks/data/produkt-entities.mock';
import {PageableProduktDTOSortingEnum} from '../../openapi/fakturierung-openapi';


export const initialProduktTableState: ProduktTableState = {
  // FIXME: Passende Diplayed-Ids laden
  displayedIds: produktEntitiesMock.ids,
  tableSettings: {
    _key: 'produkt-table-settings-2024-06-26',
    _columns: [
      {
        id: ProduktColumn.Nummer,
        name: 'Nummer',
        isActive: true,
        width: 5,
      }, {
        id: ProduktColumn.Bezeichnung,
        name: 'Produkt',
        isActive: true,
        width: 'allocate',
        overflow: 'ellipsis',
      }, {
        id: ProduktColumn.Preis,
        name: 'Preis (Netto)',
        isActive: true,
        alignCell: 'right',
        alignHeader: 'right',
        width: 10,
      }, {
        id: ProduktColumn.USt,
        name: 'USt',
        isActive: true,
        alignCell: 'right',
        alignHeader: 'right',
        width: 5,
      }, {
        id: ProduktColumn.Actions,
        name: '',
        isActive: true,
        sortable: false,
        lastCol: true,
      },
    ],
    _sortDirs: [],
    _sortParams: [],
  },
  pageable: {
    limit: 50,
    offset: 0,
    sorting: [PageableProduktDTOSortingEnum.Erstelltasc],
  },
  filter: {
    textfilter: [''],
  }
};

export const produktTableReducer = createReducer(
  initialProduktTableState,

  on(
    ProduktTableActions.changeTableSettings,
    (state, action) => ({
      ...state,
      tableSettings: action.tableSettings,
    })
  ),

  on(
    ProduktTableActions.changePage,
    (state, action) => ({
      ...state,
      pageable: {
        ...state.pageable,
        ...action.pageable,
      },
    })
  ),

  on(
    ProduktTableActions.updateSearchValue,
    (state, action) => ({
      ...state,
      filter: {
        textfilter: action.searchValue,
      }
    })
  ),
);

import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {NGXLogger} from 'ngx-logger';
import {MatSnackBar} from '@angular/material/snack-bar';
import {concatMap, delay, of, take, tap} from 'rxjs';
import {catchError, filter, map} from 'rxjs/operators';
import {mappedHttpErrorResponseOperator} from '@adnova/jf-ng-components';
import {ProduktEntitiesActions} from '../actions/produkt-entities.actions';
import {ProduktService} from '../../openapi/fakturierung-openapi';
import {SentryActions} from '../actions/sentry.actions';
import {CreateProduktDialogActions} from '../actions/create-produkt-dialog.actions';
import {Store} from '@ngrx/store';
import {ProduktEntitiesSelectors} from '../selectors/produkt-entities.selectors';
import {AppState} from '../states/app.state';


@Injectable()
export class ProduktEntitiesEffects {

  constructor(
    private actions$: Actions,
    private logger: NGXLogger,
    private snackbar: MatSnackBar,
    private produktService: ProduktService,
    private store: Store<AppState>,
  ) {
  }

  /**
   * Effekt zum Laden eines Produktes anhand der ID, sofern es noch nicht im Store vorhanden ist.
   */
  readonly loadProduktByIdIfAbsent$ = createEffect(
    () => this.actions$.pipe(
      ofType(ProduktEntitiesActions.loadProduktByIdIfAbsent),
      concatMap(action => this.store.select(ProduktEntitiesSelectors.produktById(action.produktId)).pipe(
        take(1),
        map(produkt => ({action, produkt}))
      )),
      filter(data => !data.produkt),
      map(data => ProduktEntitiesActions.loadProduktByID({
        inhaberId: data.action.inhaberId,
        produktId: data.action.produktId,
      })),
    ),
  );

  /**
   * Effekt zum Laden eines Produkts anhand der ID.
   */
  readonly loadProduktByID$ = createEffect(
    () => this.actions$.pipe(
      ofType(ProduktEntitiesActions.loadProduktByID),
      concatMap(({inhaberId, produktId}) => {
        return this.produktService.getProdukt(inhaberId, produktId).pipe(
          map((produkt) => {
            this.logger.debug(
              'load produkt by ID succeeded. produkt:',
              produkt,
            );

            return ProduktEntitiesActions.loadProduktByIdSuccess({produkt});
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            map(() => {
              this.logger.error(
                'load produkt by ID failed. produktId:',
                produktId,
                'error:',
                error,
              );

              return ProduktEntitiesActions.loadProduktByIdFailure({
                error,
              });
            }),
          )),
        );
      }),
    ),
  );

  readonly loadProduktByIdFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(ProduktEntitiesActions.loadProduktByIdFailure),
      tap(action => {

        let errorMsg = '';
        switch (action.error.status) {
          case 403 : {
            errorMsg = 'Fehlende Berechtigung für das Laden des Produkts. ' +
              'Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 404 : {
            errorMsg = 'Produkt nicht gefunden. Bitte probiere es später erneut.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Laden des Produkts. Bitte probiere es später erneut.';
          }
        }

        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          }
        );
      })
    ),
    {dispatch: false},
  );

  /**
   * Effekt zum Erstelllen eines Produkts.
   */
  readonly createProdukt$ = createEffect(
    () => this.actions$.pipe(
      ofType(ProduktEntitiesActions.createProdukt),
      concatMap(({
                   inhaberId,
                   produktDto,
                 }) => {

        return this.produktService.createProdukt(inhaberId, produktDto).pipe(
          map(() => {
            this.logger.debug(
              'create produkt entitie succeeded. produkt id:',
              inhaberId,
              produktDto,
            );

            return ProduktEntitiesActions.createProduktSuccess();
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            map(() => {
              this.logger.error(
                'create produkt entity failed. fakturierungsbelegId:',
                produktDto,
                'error:',
                error,
              );

              return ProduktEntitiesActions.createProduktFailure({
                error,
              });
            }),
          )),
        );
      }),
    )
  );

  /**
   * Success-Handling für das Erstellen eines Fakturierungsbelegs.
   */
  readonly createProduktSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(ProduktEntitiesActions.createProduktSuccess),
      tap(() => {
        this.snackbar.open('Erfolgreich angelegt', undefined, {duration: 5000});
      }),
    ), {dispatch: false}
  );

  /**
   * Error-Handling für das Erstellen eines Produkts.
   */
  readonly createProduktFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(ProduktEntitiesActions.createProduktFailure),
      tap(action => {

        let errorMsg = '';
        switch (action.error.status) {
          case 403 : {
            errorMsg = 'Fehlende Berechtigung für das Erstellen des Produkts. ' +
              'Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 404 : {
            errorMsg = 'Inhaber nicht gefunden. Bitte probiere es später erneut.';
            break;
          }
          case 461: {
            errorMsg = 'Produktnummer bereits vergeben. Bitte wähle eine andere Produktnummer.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Erstellen des Produkts. Bitte probiere es später erneut.';
          }
        }

        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          }
        );

        return SentryActions.captureException({
          error: action.error,
          extras: {
            errorMsg,
          },
        });
      })
    )
  );

  /**
   * Effekt zum Lesen der niedgrigstern freien Produktnummer.
   */
  readonly readNextProduktnummer$ = createEffect(
    () => this.actions$.pipe(
      ofType(ProduktEntitiesActions.readNextProduktnummer),
      concatMap(({inhaberId}) => {
        return this.produktService.getNextProduktnummer(inhaberId).pipe(
          map((produktnummer) => {
            this.logger.debug(
              'read next produktnummer succeeded. next produktnummer:',
              produktnummer,
            );
            return ProduktEntitiesActions.readNextProduktnummerSuccess({produktnummer: produktnummer});
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            map(() => {
              this.logger.error(
                'read next produktnummer failed. inhaberId:',
                inhaberId,
                'error:',
                error,
              );

              return ProduktEntitiesActions.readNextProduktnummerFailure({
                error,
              });
            }),
          )),
        );
      }),
    )
  );

  /**
   * Error-Handling für das Lesen der niedgrigstern freien Produktnummer.
   */
  readonly readNextProduktnummerFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(ProduktEntitiesActions.readNextProduktnummerFailure),
      tap(action => {

        let errorMsg = '';
        switch (action.error.status) {
          case 403 : {
            errorMsg = 'Fehlende Berechtigung für das Lesen der Produktnummern. ' +
              'Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 404 : {
            errorMsg = 'Inhaber nicht gefunden. Bitte probiere es später erneut.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim lesen der Produktnummer. Bitte probiere es später erneut.';
          }
        }

        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          }
        );

        this.store.dispatch(CreateProduktDialogActions.close());

        return SentryActions.captureException({
          error: action.error,
          extras: {
            errorMsg,
          },
        });
      })
    )
  );
}

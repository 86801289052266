import {InhaberDTO} from '@adnova/jf-ng-components/lib/jf-interfaces/inhaber-dto.interface';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {inhaberMock} from '../data/inhaber.mock';


@Injectable({
  providedIn: 'root'
})
export class InhaberService {

  getInhabers(
    inhaberFilter?: any,
    inhaberPageable?: any,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<InhaberDTO[]> {
    return of(inhaberMock);
  }

}

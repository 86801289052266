import {EinheitDTO, EinheitDTOMengenartEnum} from '../../openapi/fakturierung-openapi';


export const einheitenTonnenMock: EinheitDTO = {
  einheitId: '1',
  nummer: 100,
  kurzbezeichnung: 't',
  bezeichnung: 'Tonnen',
  mengenart: EinheitDTOMengenartEnum.Gewichtsmenge,
};

export const einheitenKilogrammMock: EinheitDTO = {
  einheitId: '2',
  nummer: 200,
  kurzbezeichnung: 'kg',
  bezeichnung: 'Kiogramm',
  mengenart: EinheitDTOMengenartEnum.Gewichtsmenge,
};

export const einheitenStueckMock: EinheitDTO = {
  einheitId: '3',
  nummer: 300,
  kurzbezeichnung: 'Stk.',
  bezeichnung: 'Stück',
  mengenart: EinheitDTOMengenartEnum.Stueckmenge,
};
